<template>
  <on-click-outside :do="handleClickOutside">
    <div class="relative">
      <div @click="open = !open">
        <slot name="button">
          <button type="button" class="w-full block bg-blue text-white font-semibold rounded px-4 py-2 focus:outline-0">
            Click to open
          </button>
        </slot>
      </div>
      <div v-if="open" class="dropdown-content border p-4">
        <slot name="content">
          Dropdown
        </slot>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from 'theme/components/theme/OnClickOutside'

export default {
  name: 'Dropdown',
  props: {
    keyDropdown: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    OnClickOutside
  },
  beforeMount () {
    this.$bus.$on('close-dropdown-component', this.closeDropdownComponent)
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    handleClickOutside () {
      if (this.open) {
        this.open = false
      }
    },
    closeDropdownComponent (key = false) {
      if (this.keyDropdown !== '' && key === this.keyDropdown) {
        this.open = false
      }
    }
  }
}
</script>

<style lang="scss">
  .dropdown-content {
    position: absolute;
    width: 100%;
    margin-top: 5px;
    background: #fff;
    z-index: 999;
  }
</style>
