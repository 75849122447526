<template>
  <div id="shop-all">
    <div class="container">
      <div class="flex flex-wrap -mx-3">
        <div class="w-full lg:w-1/6 px-3">
          <div class="lg:inline-block">
            <sidebar :filters="{}" />
          </div>
        </div>
        <div class="w-full lg:w-5/6 px-3">
          <header class="flex md:items-center md:justify-between mb-6 flex-col items-start justify-start md:flex-row">
            <h1 class="text-heading-page md:text-heading-page md:heading-page-xl text-primary">
              {{ $t('All') }}
            </h1>
            <div class="w-full md:w-auto mt-4 md:mt-0">
              <div class="flex justify-between items-center mb-2">
                <span>{{ $t('Filter By') }}</span>
                <span class="text-primary cursor-pointer" @click="() => selectedFilterBy = null" v-if="selectedFilterBy">
                  <svg class="icon-base mr-1 text-primary fill-current"><use xlink:href="/assets/icons.svg#close" /></svg>
                  {{ $t('Reset filter') }}
                </span>
              </div>
              <dropdown class="dropdown-filterby" :key-dropdown="'filter-by'">
                <template slot="button">
                  <button-outline :color="'primary'" class="group w-full">
                    <span v-if="selectedFilterBy">{{ filterByOptions[selectedFilterBy] }}</span>
                    <span v-else>{{ $t('Select Benefit') }}</span>
                    <svg class="icon-base fill-current text-primary ml-2 group-hover:text-white"><use xlink:href="/assets/icons.svg#caret-down" /></svg>
                  </button-outline>
                </template>
                <template slot="content">
                  <ul class="list-none p-0">
                    <li v-for="(item, key) in filterByOptions" :key="key" class="hover:text-primary font-semibold my-2 cursor-pointer" @click="() => { selectFilterBy(key)}">
                      {{ item }}
                    </li>
                  </ul>
                </template>
              </dropdown>
            </div>
          </header>
          <div class="products-list">
            <template v-for="(products, i) in productsList">
              <h2 class="text-primary text-heading my-5" :key="i" v-if="(selectedFilterBy && products.toShow > 0) || !selectedFilterBy">
                {{ products.categoryName }}
              </h2>
              <transition-group class="product-list flex flex-wrap justify-center md:justify-start mt-8 overflow-hidden" :key="i + products.categoryName" name="product">
                <div v-if="(firstKeyProductList === i) && !selectedFilterBy" class="product-listing__item justify-center items-center p-6 w-full md:w-1/3 lg:w-1/3 xl:w-1/4 relative border-t hidden md:flex" :key="i + 'first-tile'">
                  <div class="lg:p-6">
                    <div class="text-h1 text-secondary mb-5">
                      {{ $t('Free shipping on all orders!') }}
                    </div>
                    <div class="text-h3">
                      {{ $t('Select your free samples at checkout.') }}
                    </div>
                  </div>
                </div>
                <div
                  v-for="product in products.products"
                  :key="product.id + products.categoryName"
                  class="projects-item product-listing__item w-full md:w-1/3 lg:w-1/3 xl:w-1/4 relative border-t"
                  v-show="!selectedFilterBy || checkInCat(product)"
                >
                  <product-tile :product="product" :color="'primary'" />
                </div>
              </transition-group>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/core/blocks/Category/Sidebar'
import ProductListing from 'theme/components/core/ProductListing'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import Dropdown from 'theme/components/theme/blocks/Dropdown/Dropdown'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import ProductTile from 'theme/components/core/ProductTile'

export default {
  components: {
    ProductListing,
    Sidebar,
    Dropdown,
    ButtonOutline,
    ProductTile
  },
  data () {
    return {
      products: [],
      filterByOptions: {
        4: 'Hydrate',
        28: 'Repair',
        12: 'Style',
        21: 'Texture',
        3: 'Volume',
        36: 'Shine',
        10: 'Smooth',
        27: 'Curls',
        38: 'Color Safe',
        34: 'Men'
      },
      selectedFilterBy: null
    }
  },
  async beforeMount () {
    let searchProductQuery = new SearchQuery()

    const res = await this.$store.dispatch('product/list', {
      query: searchProductQuery,
      sort: 'created_at:desc'
    })

    if (res) {
      this.products = res.items
    }
  },
  created () {
  },
  computed: {
    firstKeyProductList: function () {
      return Object.keys(this.productsList)[0]
    },
    productsList: function () {
      let productsByCategory = {}

      for (let product in this.products) {
        const currentProduct = this.products[product]
        // eslint-disable-next-line no-unused-vars
        let productBelongToCat = false

        // if (currentProduct.category.length <= 0) continue
        let productInCategories = currentProduct.category

        if (this.selectedFilterBy) {
          for (let cat in productInCategories) {
            if (parseInt(productInCategories[cat].category_id) === parseInt(this.selectedFilterBy)) {
              productBelongToCat = true
            }
          }

          if (productBelongToCat === false) {
            currentProduct.show = false
          } else {
            currentProduct.show = true
          }
        }

        for (let cat in productInCategories) {
          const category = productInCategories[cat]

          if (typeof productsByCategory[category.category_id] === 'object') {
            productsByCategory[category.category_id].products.push(currentProduct)
          } else {
            productsByCategory[category.category_id] = {
              toShow: 0,
              categoryName: category.name,
              products: [currentProduct]
            }
          }

          if (this.selectedFilterBy && productBelongToCat) {
            productsByCategory[category.category_id].toShow = productsByCategory[category.category_id].toShow + 1
          }
        }
      }

      return productsByCategory
    }
  },
  methods: {
    checkInCat (currentProduct) {
      // eslint-disable-next-line no-unused-vars
      let productBelongToCat = false

      if (currentProduct.category.length <= 0) return false
      const productInCategories = currentProduct.category

      if (this.selectedFilterBy) {
        for (let cat in productInCategories) {
          if (parseInt(productInCategories[cat].category_id) === parseInt(this.selectedFilterBy)) {
            productBelongToCat = true
          }
        }
        return productBelongToCat
      }
      return true
    },
    notify () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Please select the field which You like to sort by'),
        action1: { label: this.$t('OK') }
      })
    },
    selectFilterBy (filterId) {
      this.$bus.$emit('close-dropdown-component', 'filter-by')
      this.selectedFilterBy = filterId
    }
  }
}
</script>

<style lang="scss" scoped>
  .isotop-wrap {
  }
  .dropdown-filterby {
    min-width: 200px;
  }

  .category-title {
    line-height: 65px;
  }

  @media (max-width: 770px) {
    .category-title {
      margin: 0;
      font-size: 36px;
      line-height: 40px;
    }

    .products-list {
      width: 100%;
      max-width: none;
    }
    .sorting {
      display: none;
    }

    .mobile-sorting {
      display: block;
    }

    .category-filters {
      display: none;
    }

    .product-listing {
      justify-content: center;
    }

  }

  .close-container {
    left: 0;
  }

  .close {
    margin-left: auto;
  }
</style>

<style lang="scss">
  .product {
    transform-origin: 10% 50%;
    backface-visibility: hidden;

    &-move { transition: all 600ms ease-in-out 50ms }
    &-enter-active { transition: all 300ms ease-out }

    &-leave-active {
      transition: all 200ms ease-in;
      position: absolute;
      z-index: 0;
    }

    &-enter,
    &-leave-to { opacity: 0 }
    &-enter { transform: scale(0.9) }
  }
</style>
