<template>
  <div class="sidebar">
    <h2 class="hidden lg:block pb-6 mb-6 text-grey-1 border-b text-base font-normal">
      {{ $t('Categories') }}
    </h2>
    <ul class="category-list list-none p-0">
      <li v-for="(cat, i) in categories" :key="i" class="mb-3 font-semibold">
        <router-link
          class="text-black hover:text-primary"
          :to="localizedRoute({ name: 'category', params: { id: cat.id, slug: cat.slug }})"
        >
          {{ cat.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// import Sidebar from '@vue-storefront/core/compatibility/components/blocks/Category/Sidebar'

export default {
  name: 'Sidebar',
  components: {
  },
  computed: {
    categories: function () { return this.$store.getters['category/getCategories'] }
  },
  mixins: []
}
</script>

<style lang="scss" scoped>
  .sidebar {
    .category-list {
      @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        padding: 50px 0;
        margin-bottom: 30px;
        margin-right: -1.875rem;
        margin-left: -1.875rem;
        border-bottom: 1px solid;
        @apply text-grey-4;

        li {
          margin: 0 10px;
          a {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
